/** @jsx jsx */
import { useState, useEffect } from "react"
import { uniq } from "lodash"
import { Flex, jsx } from "theme-ui"
import { Themed } from "@theme-ui/mdx"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby"

import { Grid } from "@theme-ui/components"
import { rem } from "@src/theme"

import Container from "@components/Container"
import Banner from "@components/Banner"
import Layout from "@components/Layouts"
import SEO from "@components/seo"
import ProductModal from "@shop/components/ProductModal"
import Section from "@components/Section"
import IconLink from "@components/IconLink"
import ProductCard from "@shop/components/ProductCard"
import { BodyLarge } from "@components/primitives/Text"
import AccordionWithBanner from "@components/Accordion/WithBanner"

const calloutStackData = {
  bgColor: "pink",
  title: "We've got you covered!",
  blocks: [
    {
      text: "Shop pride",
      link: "/shop/pride",
      color: "pink",
      bg: "purple",
    },
    {
      text: "Shop schools",
      link: "/shop/school",
      bg: "turquoise",
      color: "darkPurple",
    },
    {
      text: "Shop workplaces",
      link: "/shop/workplace",
      bg: "darkPurple",
      color: "white",
    },
  ],
}

const localStorageKey = "recentlyViewedProducts"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
  ...props
}) {
  const { shopifyProduct, allProducts, og } = data || {}

  const { title, media, secondaryDescription, secondaryTitle } =
    shopifyProduct || {}
  const { relatedProducts: relatedProductsById } = pageContext || {}
  const moreInfoItems = og.frontmatter?.accordion.accordionItems

  let img = (media && media[1]?.image?.originalSrc) || null

  if (!img) {
    img = media[0]?.image?.originalSrc || null
  }

  const [relatedProducts, setRelatedProducts] = useState()
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([])

  // Set product into recently viewed local storage
  const setRecentlyViewed = () => {
    const localStorage = window.localStorage

    // Grab current recently viewed items from local storage
    let recentlyViewed = localStorage.getItem(localStorageKey)
    try {
      recentlyViewed = JSON.parse(recentlyViewed) || []
    } catch (e) {
      recentlyViewed = []
    }

    // Push product id to front of list
    recentlyViewed.unshift(shopifyProduct.shopifyId)

    // Remove duplicates
    recentlyViewed = uniq(recentlyViewed)

    // Limit recently viewed to 5 products
    recentlyViewed = recentlyViewed.slice(0, 4)

    localStorage.setItem(localStorageKey, JSON.stringify(recentlyViewed))
  }

  const getRecentlyViewed = () => {
    const productsById = {}
    const products = allProducts?.products || []

    // Put products into object for easy access
    products.forEach(product => {
      productsById[product.shopifyId] = product
    })

    // Get recently viewed product ids
    let recentlyViewed = localStorage.getItem(localStorageKey)
    try {
      recentlyViewed = JSON.parse(recentlyViewed) || []
    } catch (e) {
      recentlyViewed = []
    }

    // Get list of products with those ids
    const recentlyViewedProducts = recentlyViewed.map(id => productsById[id])

    setRecentlyViewedProducts(recentlyViewedProducts || [])
  }

  useEffect(() => {
    const productsById = {}
    const products = allProducts?.products || []

    // Get related products
    products.map(product => (productsById[product.shopifyId] = product))
    const relatedProducts = (relatedProductsById || []).map(
      relatedProduct => productsById[relatedProduct.id]
    )
    relatedProducts.length = 4
    setRelatedProducts(relatedProducts || [])

    getRecentlyViewed()
    setRecentlyViewed()
  }, [])

  if (!relatedProducts) return null

  return (
    <Layout>
      <SEO title={title} pathname={props.location.pathname} image={img} />
      <Section
        className="blue-linear"
        smallSpacer
        sx={{
          bg: "blue",
          color: "darkPurple",
        }}
      >
        <Container>
          <div sx={{ mb: rem(40) }}>
            <IconLink
              onClick={() =>
                document.referrer ? navigate("/shop") : navigate(-1)
              }
              type="arrowLeft"
              label="Back to Shop"
            />
          </div>
          <Content>
            <div
              sx={{
                p: [4, null, 6],
              }}
            >
              {shopifyProduct && (
                <ProductModal active={true} productFromProps={shopifyProduct} />
              )}
            </div>
          </Content>
        </Container>

        {secondaryDescription &&
          secondaryDescription.value &&
          secondaryTitle &&
          secondaryTitle.value && (
            <section
              sx={{
                mt: 10,
              }}
            >
              <Container>
                <Flex
                  sx={{
                    justifyContent: ["center", null, "space-between"],
                    alignItems: "center",
                    flexWrap: ["wrap", null, "nowrap"],
                  }}
                >
                  <div>
                    <Themed.h3
                      sx={{
                        mt: 0,
                        mb: rem(30),
                        lineHeight: 1,
                      }}
                    >
                      {secondaryTitle.value}
                    </Themed.h3>
                    <BodyLarge
                      style={{
                        maxWidth: rem(812),
                        mb: [rem(30), rem(30), 0, 0],
                      }}
                    >
                      {secondaryDescription.value}
                    </BodyLarge>
                  </div>
                  <img
                    src={img}
                    alt="Feature image"
                    sx={{
                      height: ["100%", rem(270), null, rem(327)],
                      width: ["100%", rem(270), null, rem(327)],
                      ml: [null, null, rem(80), rem(150)],
                      borderRadius: "50%",
                    }}
                  />
                </Flex>
              </Container>
            </section>
          )}

        {relatedProducts && relatedProducts.length ? (
          <section
            sx={{
              mt: 10,
              pt: 6,
              pb: 12,
            }}
          >
            <Container>
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: ["column", null, "row"],
                  mb: 7,
                }}
              >
                <div
                  sx={{
                    mb: [rem(20), null, 0],
                  }}
                >
                  <Banner title="Related Items" />
                </div>

                <Link
                  to="/shop"
                  sx={{
                    fontFamily: "heading",
                    color: "currentColor",
                    fontWeight: 900,
                    textTransform: "uppercase",
                  }}
                >
                  Browse All Items
                </Link>
              </div>

              <Grid gap={rem(32)} columns={[1, 2, 3, 4]}>
                {relatedProducts.map(product =>
                  product !== undefined ? (
                    <ProductCard key={product.id} data={product} />
                  ) : null
                )}
              </Grid>
            </Container>
          </section>
        ) : null}

        {recentlyViewedProducts && recentlyViewedProducts.length ? (
          <section
            sx={{
              pt: 6,
              pb: 12,
            }}
          >
            <Container>
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: ["column", null, "row"],
                  mb: 7,
                }}
              >
                <div
                  sx={{
                    mb: [rem(20), null, 0],
                  }}
                >
                  <Banner title="Recently Visited" />
                </div>
              </div>

              <Grid gap={rem(32)} columns={[1, 2, 3, 4]}>
                {recentlyViewedProducts.map(product =>
                  product !== undefined ? (
                    <ProductCard key={product.id} data={product} />
                  ) : null
                )}
              </Grid>
            </Container>
          </section>
        ) : null}

        {moreInfoItems ? (
          <AccordionWithBanner
            items={moreInfoItems}
            title="More Information"
            noMaxWidthBanner
          />
        ) : null}
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    allProducts: shopifyCollection {
      ...ShopifyCollectionFragment
    }
    shopifyProduct(id: { eq: $id }) {
      orderLimit: metafield(key: "checkoutLimit", namespace: "custom") {
        value
      }

      variants {
        shopifyId
        price
        compareAtPrice
        title
        availableForSale
        inventoryQuantity
        image {
          originalSrc
        }
        selectedOptions {
          name
          value
        }
      }
      options {
        shopifyId
        name
        values
      }
      shopifyId
      title
      descriptionHtml
      secondaryDescription: metafield(
        key: "secondaryDescription"
        namespace: "custom"
      ) {
        value
      }
      secondaryTitle: metafield(key: "secondaryTitle", namespace: "custom") {
        value
      }
      media {
        ... on ShopifyMediaImage {
          image {
            gatsbyImageData
            originalSrc
          }
        }
      }
    }
    og: markdownRemark(fileAbsolutePath: { glob: "**/pages/shop/index.md" }) {
      frontmatter {
        accordion {
          ...Accordion
        }
      }
    }
  }
`

const Content = styled.section`
  width: 100%;
  height: 100%;

  background: white;
`

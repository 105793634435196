/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import styled from "styled-components"
import { Link } from "gatsby"
import theme, { rem } from "@src/theme"
import { useState } from "react"
import slugify from "slugify"
import { percentageOff } from "@helpers/utils/percentageOff"
import Price from "@shop/components/Price"

const ProductCardContent = ({ data, onClick }) => {
  const [isHovered, setIsHovered] = useState(false)

  const img =
    data.media && data.media.length ? data.media[0].image.originalSrc : false
  const secondImg =
    data.media && data.media.length > 1
      ? data.media[1].image.originalSrc
      : false

  const firstVariant = data.variants[0]

  const compareAtPercentage = percentageOff(
    firstVariant.price,
    firstVariant.compareAtPrice
  )
  const available = data.variants.some(variant => variant.availableForSale)

  return (
    <ItemCard
      to={`/products/${slugify(data.title.toLowerCase())}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <figure
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          color: "currentColor",
          height: "100%",
          position: "relative",
          zIndex: 1,
        }}
      >
        <ItemImage
          sx={{
            backgroundImage: `url(${img})`,
          }}
        >
          {compareAtPercentage ? (
            <div
              sx={{
                "z-index": 20,
                position: "absolute",
                bottom: 3,
                right: 0,
                backgroundColor: "red",
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100,
                color: "white",
                fontSize: "16px",
                py: 1,
                px: 2,
              }}
            >
              {compareAtPercentage}% off
            </div>
          ) : null}

          {secondImg ? (
            <ItemImage
              sx={{
                opacity: isHovered ? 1 : 0,
                "z-index": 10,
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage: `url(${secondImg})`,
                transition: "all 200ms ease",
              }}
            />
          ) : null}
        </ItemImage>

        <div
          sx={{
            p: rem(20),
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <figcaption
            as="p"
            sx={{
              lineHeight: 1.6,
              mb: rem(20),
              fontSize: rem(18),
            }}
          >
            {data.title}
          </figcaption>
          <Price
            price={firstVariant.price}
            compareAtPrice={firstVariant.compareAtPrice}
            available={available}
            styles={{
              fontSize: [rem(16), rem(18)],
            }}
          />
        </div>
      </figure>
    </ItemCard>
  )
}

export default ProductCardContent

const ItemImage = styled.div`
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  // Force height to be 100% of the width
  height: 0;
  padding-bottom: 100%;
  width: 100%;
`

const ItemCard = styled(props => <Link {...props} />)`
  background: white;
  cursor: pointer;
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    background-color: ${theme.colors.darkPurple};
    transition: background-color 300ms ease, color 300ms ease;

    figcaption {
      color: ${theme.colors.white};
      text-decoration: underline;
      text-underline-offset: 2px;
    }

    p.price {
      color: ${theme.colors.white};
    }
  }
`
